<template>
    <div class="addBusinessPage">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="$router.back()"
                >返回</el-button
            >
            <div class="title">{{ dialogTitle }}</div>
        </div>
        <el-card class="box-card userInfo">
            <div slot="header" class="clearfix">
                <span class="userInfo_title">商户信息</span>
                <i
                    class="el-icon-paperclip"
                    style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                ></i>
            </div>
            <el-form
                :model="userForm"
                ref="userForm"
                :rules="rules"
                label-width="120px"
                class="userForm"
                :inline="true"
                :disabled="$route.query.edit==0"
            >
                <el-form-item label="租户名称" prop="name">
                    <el-input
                        size="small"
                        v-model="userForm.name"
                        placeholder="输入商户名称"
                        style="width: 400px"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="商户ID" prop="merchantId">
                    <el-input
                        size="small"
                        v-model="userForm.merchantId"
                        placeholder="输入商户ID"
                        style="width: 400px"
                    ></el-input>
                </el-form-item>
                
                <el-form-item label="支付平台" prop="paymentPlatformType">
                    <el-select v-model="userForm.paymentPlatformType" placeholder="选择支付平台" style="width: 400px" size="small">
                        <el-option
                            v-for="item in platformOptions"
                            :key="item.value"
                            :label="item.value"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="私钥密码" prop="privateKeyPassword" v-if="userForm.paymentPlatformType=='TLT'">
                    <el-input
                        size="small"
                        v-model="userForm.privateKeyPassword"
                        placeholder="输入私钥密码"
                        style="width: 400px"
                    ></el-input>
                </el-form-item>
                <div class="publicKey" v-if="userForm.paymentPlatformType=='HEEPAY'">
                    <el-card class="box-card publicKey_content">
                        <div slot="header" class="clearfix">
                            <span class="publicKey_title">平台公钥</span>
                            <i
                                class="el-icon-paperclip"
                                style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                            ></i>
                        </div>
                        <el-form-item prop="publicKey">
                            <el-input
                                type="textarea"
                                :rows="10"
                                placeholder="输入平台公钥"
                                v-model="userForm.publicKey"
                            >
                            </el-input>
                        </el-form-item>
                    </el-card>
                    <el-card class="box-card publicKey_content">
                        <div slot="header" class="clearfix">
                            <span class="publicKey_title">商户私钥</span>
                            <i
                                class="el-icon-paperclip"
                                style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                            ></i>
                        </div>
                        <el-form-item prop="privateKey">
                            <el-input
                                type="textarea"
                                :rows="10"
                                placeholder="输入商户私钥"
                                v-model="userForm.privateKey"
                            >
                            </el-input>
                        </el-form-item>
                    </el-card>
                </div>

                <div class="publicKey" v-else-if="userForm.paymentPlatformType=='TLT'">
                    <!-- <el-card class="box-card publicKey_content">
                        <div slot="header" class="clearfix">
                            <span class="publicKey_title">平台公钥</span>
                            <i
                                class="el-icon-paperclip"
                                style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                            ></i>
                        </div>
                        <el-form-item prop="publicKey">
                            <el-upload
                                class="upload-demo"
                                action="null"
                                :show-file-list="true"
                                :on-preview="onPreview"
                                :before-upload="publicKeyUpload"
                                :file-list="publicKeyFileList"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">上传文件格式为 .crt</div>
                            </el-upload>

                           
                        </el-form-item>
                    </el-card> -->
                    <el-card class="box-card publicKey_content">
                        <div slot="header" class="clearfix">
                            <span class="publicKey_title">商户私钥</span>
                            <i
                                class="el-icon-paperclip"
                                style="float: right; padding: 3px 0; font-size: 18px; font-weight: 700"
                            ></i>
                        </div>
                        <el-form-item prop="privateKey">
                            <el-upload
                            class="upload-demo"
                           
                            :show-file-list="true"
                            action="null"
                            :before-upload="privateKeyUpload"
                            :on-preview="onPreview"
                            :file-list="privateKeyFileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传文件格式为 .p12</div>
                            </el-upload>
                        </el-form-item>
                    </el-card>
                </div>
            </el-form>
            <div class="btn">
                <el-button type="primary" plain size="small" @click="submit('userForm')" v-if="$route.query.edit!=0">保 存</el-button>
            </div>
        </el-card>
    </div>
</template>
<script>
import businessConfigureApi from "../../api/businessConfigureApi";
export default {
    name: "addBusinessPage",
    data() {
        return {
            userForm: {},
            dialogTitle: "创建商户",

            rules: {
                name: [{ required: true, message: "未输入商户名称", trigger: "blur" }],
                merchantId: [{ required: true, message: "未输入商户ID", trigger: "blur" }],
                privateKeyPassword: [{ required: true, message: "未输入私钥密码", trigger: "blur" }],
               
                paymentPlatformType: [{ required: true, message: "未选择支付平台", trigger: "change" }],
               
            },

            platformOptions: [],

            publicKeyFileList:[],
            privateKeyFileList:[],

            frontImageUrl:''
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.$route.query.edit ?  this.dialogTitle = "编辑商户" : this.dialogTitle = "查看商户";
            this.getMerchantData();
        } else {
            this.dialogTitle = "开通商户";
        }
        this.getPlatformList();
        this.userForm.name=this.$route.query.name
    },
    methods: {
        calculateDate() {
            if (this.userForm.duration !== null && this.userForm.duration !== "") {
                const today = new Date();
                const futureDate = new Date(
                    today.getFullYear() + this.userForm.duration,
                    today.getMonth(),
                    today.getDate()
                );

                this.userForm.date = [this.formatDate(today), this.formatDate(futureDate)];
            } else {
                this.userForm.date = [];
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        getMerchantData(){
            businessConfigureApi.platformGet({
                id:this.$route.query.id
            }).then(res=>{
                if (res.success) {
                   
                    
                    this.userForm=res.data
                    let privateKeySrcName=this.userForm.privateKeySrc.split('/')[this.userForm.privateKeySrc.split('/').length-1]
                    let publicKeyFileName=this.userForm.publicKeySrc.split('/')[this.userForm.publicKeySrc.split('/').length-1]
                    
                    let privateKeyFile={
                        name:privateKeySrcName,
                        url:'https://safepayment.com.cn:18081/api/attachment'+this.userForm.privateKeySrc
                    }
                    this.privateKeyFileList.push(privateKeyFile)

                    let publicKeyFile={
                        name:publicKeyFileName,
                        url:'https://safepayment.com.cn:18081/api/attachment'+this.userForm.publicKeySrc
                    }
                    this.publicKeyFileList.push(publicKeyFile)

                    this.userForm.name=this.$route.query.name
                }else{
                    this.$message({
                        type: "warning",
                        message: res.msg
                    });
                }
            })
        },

        onPreview(file){
            console.log(file);
            var a = document.createElement("a");
            a.target = "_blank";
            a.download = file.name;  // 设置下载的文件名
            a.href = file.url;       // 设置文件的URL

            // 创建并触发点击事件
            var event = new MouseEvent("click");
            a.dispatchEvent(event);
        },

      

        getPlatformList() {
            businessConfigureApi.payPlatformList().then(res => {
                if (res.success) {
                    this.platformOptions = res.data;
                }
            });
        },

        submit(formName) {
           
            this.$set(this.userForm,'disabled',false)
            this.$set(this.userForm,'description','')
            this.$set(this.userForm,'tenantId',this.$route.query.tenantId)
            this.$set(this.userForm,'merchantName',this.userForm.merchantId+'04')
          
            let formData = new FormData();
            for (let key in this.userForm) {
                formData.append(key, this.userForm[key]);
            }
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.$route.query.id) {
                        businessConfigureApi
                            .ptUpdate(formData)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "修改成功"
                                    });
                                    this.$router.back();
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "修改失败"
                                    });
                                }
                            });
                    } else {
                        businessConfigureApi
                            .ptAdd(formData)
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "创建成功"
                                    });
                                    this.$router.back();
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "创建失败"
                                    });
                                }
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        publicKeyUpload(file) {
            this.publicKeyFileList=[]
            this.publicKeyFileList.push(file)
            this.$set(this.userForm,'publicKeyFile',file)
            return false;
        },
        privateKeyUpload(file) {
            this.privateKeyFileList=[]
            this.privateKeyFileList.push(file)
            this.$set(this.userForm,'privateKeyFile',file)
            return false;
        },
    }
};
</script>
<style lang="scss" scoped>
.addBusinessPage {
    background: #fff;
    min-height: 85vh;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
        padding-right: 90px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }

    .userInfo {
        margin: 50px;
        .userInfo_title {
            font-size: 16px;
            color: #222222;
            margin-bottom: 40px;
            font-weight: 700;
        }
    }
    .publicKey_content {
        margin-top: 20px;
        background: #ffffff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;
        margin-left: 50px;
        width: 600px;
        .publicKey_title {
            font-size: 16px;
            color: #222222;
            margin-bottom: 40px;
            font-weight: 700;
        }
        .userForm {
            width: 416px;
        }

        /deep/.el-form-item__label {
            font-weight: 600;
        }

        /deep/.el-form-item,
        /deep/.el-form-item__content {
            width: 100%;
        }
    }

    .btn {
        margin-top: 60px;
        text-align: center;
    }
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}

.box-card {
    // width: 600px;
    /deep/.el-card__header {
        background: #f4f4f5;
    }
}
.publicKey {
    display: flex;
}
.userInfo {
    // width: 1500px;
}
</style>
